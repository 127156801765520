<template>
    <v-card outlined class="elevation-1">
        <v-card-title class="grey lighten-5 blue--text">
            <h5 :style="{ color: color }">{{ titulo }}</h5>
            <v-spacer />
            <h5 :style="{ color: color }">S/. {{ total }}</h5>
        </v-card-title>

        <v-card-text dense class="grey lighten-5">
            <v-row dense style="font-size: 9px" v-for="(item, key) in items" :key="key" dense>
                <v-col cols="12">
                    <b>______________</b>
                </v-col>
                <v-col cols="12">
                    <b>Ticket {{ item.idTicket }}</b>
                </v-col>
                <v-col cols="12">
                    {{ item.description | text }}
                </v-col>
                <v-col cols="12">
                    <b>{{ isformatNumber(item) }}</b>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { formatNumber } from '@/utils/genericUtils'

export default {
    filters: {
        text(val) {
            return val ? val.slice(0, 40).concat('~') : ''
        }
    },
    props: ['titulo', 'items', 'total', 'id', 'color'],
    methods: {
        isformatNumber(item) {
            const value = parseInt(item.subTotal) ? item.subTotal : item.importe
            return formatNumber(value, item.idTipoMoneda)
        }
    }
}
</script>
